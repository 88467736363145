import React, { Component } from "react";
import "./eventCard.css";
import DateIcon from "../icons/dateIcon.png";
import TimeIcon from "../icons/timeIcon.png"
import locationIcon from "../icons/locationIcon.png"
class eventCard extends Component {
  render() {
    return (
      <div
        className="cardParent"
        style={{ width: this.props.width, height: this.props.height }}
      >
        <img className="eventImage" src={this.props.eventImage}/>
        <h1 className="eventTitle"> {this.props.title}</h1>
        <div className="eventCardBody">
          

          <div className="dateContainer">
            <img className={"dateIcon"} src={DateIcon} alt=""></img>
            <div className="iconParent">
              <h2 className="dateHeading">{this.props.startDate}&nbsp;{"-"}&nbsp;</h2>
              <h2 className="dateHeading">{this.props.endDate}</h2>
            </div>
          </div>
          <div className="dateContainer">
            <img className={"dateIcon"} src={TimeIcon} alt=""></img>
            <div className="iconParent">
              <h2 className="timeHeading">{this.props.startTime}&nbsp;{"-"}&nbsp;</h2>
              <h2 className="timeHeading">{this.props.endTime}</h2>
            </div>
          </div>
            <div className="dateContainer">
            <img className={"dateIcon"} src={locationIcon} alt=""></img>
            <div className="iconParent">
            <p className="locationHeading"> {this.props.location}</p>
            </div>
          </div>
      
          <p className="informationP"> {this.props.information}</p>
          <div> {this.props.content}</div>
          <div className="eventFooter"></div>
        </div>
      </div>
    );
  }
}

export default eventCard;
