import React, { Component } from "react";
import "./sponsors.css";

const logos = importAll(require.context('../logos', false, /\.(png|jpe?g|svg)$/));
function importAll(r) {
    return r.keys().map(r);
  }
class sponsors extends Component {
  constructor(props) {
    super(props);
    this.updateImageSize= this.updateImageSize.bind(this);
  }
   logos = logos.map((key) =>
   <img className="logo" src={key} alt="logo"></img>
   );
  updateImageSize(width, height){
  
    var images= document.querySelectorAll('.logo');
    let newWidth= .6 * Math.sqrt( (width*height) / (images.length/2));
    let count= 0;
            images.forEach(function (a) {
        if(a.height>a.width){
             a.style.height=(newWidth*.75)+'px'
             a.style.width= 'auto';
             count= count +1;
         }
         else{
             a.style.width= newWidth+"px";
         }

       })
         
 }


componentDidUpdate(prevProps){
  if(prevProps !== this.props.width && ((this.props.index===4&&!this.props.Mobile)|| (this.props.index===6&&this.props.Mobile))){
          this.updateImageSize(this.props.width, this.props.height);
          
  }
}
  
  render() {
    return (
      <div>

          <div className="sponsorsParent">
            <h2 className="sponsorsHeading">
              {" "}
              A Special Thanks To Our Corporate Donors.
            </h2>
            <div className="sponsorsContainer">
       <div className="logoContainer">
               {this.logos}
              </div>
            </div>
          </div>
      
      </div>
    );
  }
}

export default sponsors;
