import React, { Component } from "react";
import EventCard from "../components/eventCard";
import { BrowserRouter as Router, Link } from "react-router-dom";
import diningForDollars from "../files/diningForDollars.jpg";
import downloadIcon from "../icons/downloadIcon.png";
import jean from "../images/jeans.jpg"
const bookSaleInfo =
  "There's another Book Sale coming soon!  3,000+ books to peruse. Old and New!  Something for everyone, every age, action packed to cook book. Date and time yet to be announced.";

const eventCard4Mobile = (
  <EventCard
    eventImage={jean}
    title={"Dining for Dollars"}
    information={
      'Each meal bought, in-house or to-go, donates $1 per meal to benefit the future Union Gap Library and Community Center.'
    }
    
    width={"85%"}
    height={"auto"}
    startDate={"May 31 2023"}
    endDate={"May 31 2023"}
    startTime={"8:30"}
    endTime={"6:30"}
    location={"3211 Main St, Union Gap, WA 98903"}
    content={
      <div>
        <Router>
          <div>
            <Link
              to={diningForDollars}
              target="_blank"
              className="downloadLink"
            >
              <img className="downloadIcon" src={downloadIcon} alt=""></img>{" "}
              Dining for Dollars Info
            </Link>
          </div>
        </Router>
      </div>
    }
  ></EventCard>
);
class eventsMobile4 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="eventsParent">
          <div className="eventsContainer">
            <h1 className="eventsHeading">Events / Fundraisers</h1>
            <div className="eventCardContainerMobile">{eventCard4Mobile} </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eventsMobile4;
