import React, { Component} from "react";
import "./gallery.css";
import exitIcon from "../icons/exitIcon.png";
import Modal from "react-modal";
import "./gallery.css"
const images = importAll(require.context('../gallery', false, /\.(png|jpe?g|svg)$/));
function importAll(r) {
    return r.keys().map(r);
  }
Modal.setAppElement("#root");


class gallery extends Component {
     galleryItems = images.map((key) =>
<img className="galleryImage" src={key}   onClick={() => { this.toggleModal(key);}} alt=""></img>
);
    constructor (props) {
        super(props);
        this.state={
            isOpen: false,
            picToShow:null,
        }
        this.toggleModal= this.toggleModal.bind(this);
        this.updateImageSize= this.updateImageSize.bind(this);
    }
     toggleModal(picSrc) {
     this.setState({isOpen:!this.state.isOpen, picToShow: picSrc});
    }

    updateImageSize(width, height){
  
       var images= document.querySelectorAll('.galleryImage');
       let newWidth= .8 * Math.sqrt( (width*height) / (images.length/2));
       let count= 0;
               images.forEach(function (a) {
           if(a.height>a.width){
                a.style.height=(newWidth*.75)+'px'
                a.style.width= 'auto';
                count= count +1;
            }
            else{
                a.style.width= newWidth+"px";
            }
          })
            
    }
    componentDidMount(){

    }
   componentDidUpdate(prevProps){
         if(prevProps !== this.props.width &&( (this.props.index===7 || this.props.index===14))){
                 this.updateImageSize(this.props.width, this.props.height)
         }
    }
    render() {

        return (
            <div>
            
            <div className="galleryParentContainer">
            <h1 className="photosHeading">Gallery</h1>
                    {this.galleryItems}                 
                </div>

      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={200}
      >
        <img className="fullGalleryImage" src={this.state.picToShow} alt=""></img>
        <img src={exitIcon} alt='src' className="exitIcon" onClick={this.toggleModal}/>

      </Modal>
            </div>
        );
    }
}

export default gallery;