import React from "react";
import Sidebar from "react-sidebar";
import "./header.css";
import Toggle from "../icons/toggle_icon.png";
import unionGapLogo from "../icons/unionGapLogoDark.png";
import letterHead from "../icons/letterheadInvert.png";
import facebook from "../icons/facebookLink.png";
import facebookDark from "../icons/facebookDark.png";
import fredMeyer from "../icons/fredMeyerRewards.PNG";
import amazonSmile from "../icons/amazonSmile.png";
import mailIcon from "../icons/mailIcon.png"
import Alert from "../components/alert.js"
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.gottoUrl = this.gottoUrl.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  handlePageClick(index) {
    this.onSetSidebarOpen(false);
    this.props.FlipToPage(index);
  }
  gottoUrl(url) {
    window.open(url);
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (prevProps.index !== this.props.index) {
      if (!this.props.Mobile) {
        let btnId = "navButton" + this.props.index;
        document.getElementById(btnId).focus();
      }
    }
  }
  render() {
    return (
      <div className="header">

        <Sidebar
          sidebar={
            <div className="navButtonContainer">
              <img src={unionGapLogo} alt="logo" className="unionGapLogo" />
    
       
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(0);
                }}
              >
                Home
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(1);
                }}
              >
                Building Plans
              </button>

              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(4);
                }}
              >
                Donate
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(5);
                }}
              >
                Thank You
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(6);
                }}
              >
                Corporate Donors
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(7);
                }}
              >
                Events / Fundraisers
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(11);
                }}
              >
                Board
              </button>
              <button
                className="navButton"
                onClick={() => {
                  this.handlePageClick(14);
                }}
              >
                Gallery
              </button>
              <div className="emailContainerMobile">  <img src={mailIcon}></img> <div>friends.uglcc@gmail.com</div></div>
              <img
                className="fbLinkMobile"
                src={facebookDark}
                alt=""

                onClick={() =>
                  this.gottoUrl(
                    "https://www.facebook.com/Friends-of-Union-Gap-Library-and-Community-Center-112981813384787"
                  )
                }
              ></img>
              <img
                className="fredMeyerMobile"
                src={fredMeyer}
                alt=""

                onClick={() =>
                  this.gottoUrl(
                    "https://www.fredmeyer.com/i/community/community-rewards"
                  )
                }
              ></img>
              <img
                className="amazonSmileMobile"
                src={amazonSmile}
                alt=""
                onClick={() =>
                  this.gottoUrl(
                    "https://smile.amazon.com/gp/chpf/homepage?orig=%2F"
                  )
                }
              ></img>
                      <p className="designMobile">design by:<a href="https://aaronahlberg.dev"> aaronahlberg.dev</a></p>
            </div>
          }
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "white", zIndex: 3 } }}
        >
          <div className="navbar">
            {this.props.ScreenWidth <= 800 && (
              <input
                className="toggleBtn"
                type="image"
                src={Toggle}
                alt="toggle button"
                onClick={() => this.onSetSidebarOpen(true)}
              />
            )}

            {this.props.ScreenWidth > 800 && (
              <div className="fullNavBar">
                <img
                  src={letterHead}
                  alt="friendsoflgcc"
                  className="letterHeadFullscreen"
                />
                <button
                  className="fullNavButton"
                  id="navButton0"
                  onClick={() => {
                    this.handlePageClick(0);
                  }}
                >
                  Home
                </button>
                <button
                  className="fullNavButton"
                  id="navButton1"
                  onClick={() => {
                    this.handlePageClick(1);
                  }}
                >
                  Building Plans
                </button>

                <button
                  className="fullNavButton"
                  id="navButton2"
                  onClick={() => {
                    this.handlePageClick(2);
                  }}
                >
                  Donate
                </button>
                <button
                  className="fullNavButton"
                  id="navButton3"
                  onClick={() => {
                    this.handlePageClick(3);
                  }}
                >
                  Thank You
                </button>
                <button
                  className="fullNavButton"
                  id="navButton4"
                  onClick={() => {
                    this.handlePageClick(4);
                  }}
                >
                  Corporate Donors
                </button>
                <button
                  className="fullNavButton"
                  id="navButton5"
                  onClick={() => {
                    this.handlePageClick(5);
                  }}
                >
                  Events / Fundraisers
                </button>

                <button
                  className="fullNavButton"
                  id="navButton6"
                  onClick={() => {
                    this.handlePageClick(6);
                  }}
                >
                  Board
                </button>
                <button
                  className="fullNavButton"
                  id="navButton7"
                  onClick={() => {
                    this.handlePageClick(7);
                  }}
                >
                  Gallery
                </button>
                {this.props.ScreenWidth >= 1250 && (  <div className="socialMediaContainer">
                  {" "}
                
                    <img
                      className="fbLink"
                      alt=""

                      src={facebook}
                      onClick={() =>
                        this.gottoUrl(
                          "https://www.facebook.com/Friends-of-Union-Gap-Library-and-Community-Center-112981813384787"
                        )
                      }
                    ></img>
             
          
                    <img
                      className="fredMeyer"
                      src={fredMeyer}
                      alt=""
                      onClick={() =>
                        this.gottoUrl(
                          "https://www.fredmeyer.com/i/community/community-rewards"
                        )
                      }
                    ></img>
            
     
                    <img
                      className="amazonSmile"
                      src={amazonSmile}
                      alt=""
                      
                      onClick={() =>
                        this.gottoUrl(
                          "https://smile.amazon.com/gp/chpf/homepage?orig=%2F"
                        )
                      }
                    ></img>
            
                </div>
                     )}
                 
              </div>
            )}
            {this.props.ScreenWidth <= 800 && (
              <img
                src={letterHead}
                alt="friendsoflgcc"
                className="letterHeadMobile"
              />
            )}
          </div>
        </Sidebar>
      </div>
    );
  }
}
export default Header;
