import React, { useState } from "react";
import "./donatePage.css";
import ProgressBar from "../components/progressBar";
import { stripePromise } from "../App";

import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";

function getFocus(id) {
  document.getElementById(id).focus();
}

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#988c88",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#dcd9cb",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  step,
  min,
  max,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow" id={"FormRow" + id} onClick={() => getFocus(id)}>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      step={step}
      min={min}
      max={max}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#000000"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: "",
  });
  const [showDonationInstructions, setShowDonationInstructions] = useState(
    false
  );

  const onfocus = (event) => {
    event.preventDefault();
  };

  async function getKey() {
    let reqBody = JSON.stringify({
      amount: amount,
      email: billingDetails.email,
      name: billingDetails.name,
    });
    var response = await fetch("https://serve.friendsofuglcc.org/secret", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: reqBody,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw new Error("ERROR: Unable to get id");
      });
    return response.client_secret;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }
    var key = await getKey().catch((error) => {
      console.error("Error:", error);
      setError(error);
    });

    const result = await stripe.confirmCardPayment(key, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });
    if (!result.error) {
      setPaymentMethod(true);
      setProcessing(false);
    } else {
      console.log("Error: " + result.error);
      setError(result.error);
      setProcessing(false);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      name: "",
    });
    setAmount("");
  };

  return paymentMethod ? (
    <div className="Result">
      
      {document.querySelectorAll(".FormGroup").forEach(function (a) {
        a.remove();
      })}
      <div className="ResultMessage">
        Thank You for Your Donation!
      </div>
      <div className="ResultTitle" role="alert">
        payment successful
      </div>
      <ResetButton onClick={reset} />
    
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit} onFocus={onfocus}>
   

      {true && (
        <fieldset className="FormGroup">
          <Field
            label="Other"
            id="other"
            type="number"
            step="0.01"
            min="1"
            max="999999"
            placeholder="0"
            autoComplete="none"
            value={amount}
            required
            onChange={(e) => {
              setAmount(e.target.value);
              document.getElementById('other').style.width=  e.target.value.length+'ch';
            }}
            onFocus={onfocus}
            
          />
          <div className="payButtonContainer">
            <button
              className="amountButton"
              type="button"
              value={"10"}
              onClick={(e) => {
                setAmount(e.target.value);
                document.getElementById('other').style.width= e.target.value.length+'ch';
              }}
              
            >
              $10
            </button>
            <button
              className="amountButton"
              type="button"
              value={"100"}
              onClick={(e) => {
                setAmount(e.target.value);
                document.getElementById('other').style.width= e.target.value.length+'ch';
              }}
            >
              $100
            </button>
            <button
              className="amountButton"
              type="button"
              value={"1000"}
              onClick={(e) => {
                setAmount(e.target.value);
                document.getElementById('other').style.width= e.target.value.length+'ch';
              }}
            >
              $1k
            </button>
            <button
              className="amountButton"
              type="button"
              value={"5000"}
              onClick={(e) => {
                setAmount(e.target.value);
                document.getElementById('other').style.width= e.target.value.length+'ch';
              }}
            >
              $5k
            </button>
          </div>
          <div className="nameEmailConatiner">
            <Field
              label="Name"
              id="name"
              type="text"
              placeholder="Full Name"
              required
              autoComplete="name"
              value={billingDetails.name}
              onChange={(e) => {
                setBillingDetails({ ...billingDetails, name: e.target.value });
              }}
            />
            <Field
              label="Email"
              id="email"
              type="email"
              placeholder="Email"
              required
              autoComplete="email"
              value={billingDetails.email}
              onChange={(e) => {
                setBillingDetails({ ...billingDetails, email: e.target.value });
              }}
            />
          </div>

          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />

          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          <SubmitButton
            processing={processing}
            error={error}
            disabled={!stripe}
          >
            Donate
          </SubmitButton>

          {!showDonationInstructions && (
            <button
              className="donateInPersonButton"
              type="button"
              onClick={() => {
                    setShowDonationInstructions(true);
                    document
                      .querySelectorAll(".instructionParent")
                      .forEach(function (a) {
                        a.remove();
                      });
                  }}
                >
              {" "}
              To Donate In Person Click Here
            </button>
          )}

          {showDonationInstructions && (
            <div className="instructionParent">
              <div className="donationInfoParent">
                <p className="addressTitle">You can mail your donation to:</p>
                <p className="addressText">
                  Friends of Union Gap Library <br /> and Community Center{" "}
                  <br />
                  PO Box 3132 Union Gap, WA 98903
                </p>
              </div>
              <div className="donationInfoParent">
          
              </div>
              {showDonationInstructions && (
                <button
                  className="donateInPersonButton"
                  type="button"
                  onClick={() => {
                    setShowDonationInstructions(false);
                  }}
                >
                  {" "}
                  close
                </button>
              )}
            </div>
          )}
        </fieldset>
      )}
    </form>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

class donatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runAnimation: false,
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {}
  render() {
    return (
      <div>
        {this.props.Mobile && (
          <div
            className="parentContainerDonate"
            style={{
              height: this.props.height - 50 + "px",
              position: "relative",
              bottom: "0px",
            }}
          >
            <div
              className="donationCardParentMobile"
              id="donationCardParentMobile"
            >
              <div className="donationCardMobile" id="donationCardMobile">
                <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                  <CheckoutForm />
                </Elements>
              </div>
            </div>
            <div className="infoCardParentMobile">
              <div className="infoCard">
                <h1 className="donationHeading">Donation Tracker</h1>
                <ProgressBar Mobile={true} Index={this.props.Index} />
              </div>
            </div>
          </div>
        )}
        {!this.props.Mobile && (
          <div className="donationContainer">
            <div className="parentContainerDonate">
              <div className="donationCardParent">
                <div className="donationCard">
                  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                    <CheckoutForm />
                  </Elements>
                </div>
              </div>
              <div className="infoCardParent">
                <div className="infoCard">
                  <h1 className="donationHeading">Donation Tracker</h1>
                  <ProgressBar Mobile={false} Index={this.props.Index} />
                </div>
              </div>
            </div>
            <div className="donationAmountContainer">
              <h1 className="donorLevelPage">Donor Levels</h1>
              <div className="donorLevelContainer" id="platinum">
                <h1 className="donorLevelHeading">
                  Platinum Donor
                  <h1 className="levelAmount">
                    <br></br>$5,000+{" "}
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Plaque</li>
                  <li>Name in newsletter with photo</li>
                  <li>Honorable Mention on our FB page and Website</li>
                  <li>
                    Name on plaque in entry at the Library and Community Center
                  </li>
                  <li>Personalized Legacy Brick</li>
                  <li>Invite to our Annual Donor Event</li>
                </ul>
              </div>
              <div className="donorLevelContainer" id="gold">
                <h1 className="donorLevelHeading">
                  Gold Donor
                  <h1 className="levelAmount">
                    <br></br>$1,000+
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Name on group plaque in entry</li>
                  <li>Name in newsletter with photo</li>
                  <li>Honorable Mention on our FB page and Website</li>
                  <li>5 x 7 plaque</li>
                  <li>Invite to our Annual Donor Event</li>
                </ul>
              </div>
              <div className="donorLevelContainer" id="silver">
                <h1 className="donorLevelHeading">
                  Silver Donor
                  <h1 className="levelAmount">
                    <br></br>$500+
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Name in newsletter with group photo</li>
                  <li>Honorable Mention on our FB page and Website</li>
                  <li>4 x 6 plaque</li>
                  <li>Invite to our Annual Donor Event</li>
                </ul>
              </div>
              <div className="donorLevelContainer" id="bronze">
                <h1 className="donorLevelHeading">
                  Bronze Donor{" "}
                  <h1 className="levelAmount">
                    <br></br>$100+
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Name in newsletter</li>
                  <li> Listing on FB page and Website</li>
                </ul>
              </div>

              <div className="donorLevelContainer">
                <h1 className="donorLevelHeading">
                  Patron Donor{" "}
                  <h1 className="levelAmount">
                    <br></br>&lt; $99
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Name in newsletter</li>
                  <li> Listing on FB page</li>
                </ul>
              </div>
              <div className="donorLevelContainer">
                <h1 className="donorLevelHeading">
                  Challenge Level
                  <h1 className="levelAmount">
                    <br></br> Group{" "}
                  </h1>
                </h1>
                <ul className="donorLevelUl">
                  <li>Donate and Challenge your Freinds!</li>
                  <li>Name in newsletter with group photo</li>
                  <li>Listed on UGLCC Challenge Plaque</li>
                  <li> Special seating at our Annual Donor Event</li>
                  <li> Honorable Mention on our FB page and Website</li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default donatePage;
