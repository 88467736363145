import React from "react";
import "./members.css";
import Profile from "../components/profile";
import Julie from "../profiles/julie.jpg";
import Mark from "../profiles/mark.jpg";
import Helen from "../profiles/helen.jpg";
import eddy from "../profiles/eddy.jpg";
import Theresa from "../profiles/theresa.JPG";
import Debra from "../profiles/debra.jpg"
import Imelda from "../profiles/Imelda.jpg"
import Lilia from "../profiles/Lilia.jpg"
import Kathy from "../profiles/kathy.jpg";
import Jim from '../profiles/jim.jpg';
import Grace from "../profiles/grace.jpg"
import mailIcon from "../icons/mailIcon.png"
import Benine from "../profiles/bernie.jpeg"
class members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="parentContainerMembers">

        <h1 className="pageTitle">Board Members</h1>
        
        <Profile
          Name={"Mark Crouchet"}
          Description={"Board Chairman"}
          Image={Mark}
          width={"25%"}
          height={"25%"}
        />
        <Profile
          Name={"Julie Schilling"}
          Description={"Vice-Chair"}
          Image={Julie}
          width={"25%"}
          height={"25%"}
        />
            <Profile
          Name={"Lilia Gomez-Munguia"}
          Description={"Treasurer"}
          Image={Lilia}
          width={"25%"}
          height={"25%"}
        />
          <Profile
          Name={"Theresa Charvet"}
          Image={Theresa}
          Description={"Secretary"}
          width={"25%"}
          height={"25%"}
        />
        <Profile
          Name={"Helen Canatsey"}
          Description={"Board Member"}
          Image={Helen}
          width={"25%"}
          height={"25%"}
        />
      
    
        <Profile
          Name={"Debra Delatorre"}
          Description={"Board Member"}
          Image={Debra}
          width={"25%"}
          height={"25%"}
        />

        <Profile
          Name={"Imelda Vargas"}
          Description={"Board Member"}
          Image={Imelda}
          width={"25%"}
          height={"25%"}
        />
         <Profile
          Name={"Eddy Hernandez"}
          Description={"Board Member"}
          Image={eddy}
          width={"25%"}
          height={"25%"}
        />
            <Profile
        Name={"Benine McDonnell"}
          Description={"Board Member"}
          Image={Benine}
          width={"25%"}
          height={"25%"}>
        </Profile>
        <Profile
        Name={"Jim Honeyford"}
          Description={"Honorary Board Member"}
          Image={Jim}
          width={"25%"}
          height={"25%"}>
        </Profile>
        <Profile
        Name={"Kathy Cluck"}
          Description={"Honorary Board Member"}
          Image={Kathy}
          width={"25%"}
          height={"25%"}>
        </Profile>
        <Profile
        Name={"Grace Federspiel"}
          Description={"Student Member"}
          Image={Grace}
          width={"25%"}
          height={"25%"}>
        </Profile>
       
      
        <div className="emailContainer">  <img style={{margin:'5px'}} src={mailIcon} alt="mailIcon"></img> <div>friends.uglcc@gmail.com</div></div>
      </div>
    );
  }
}
export default members;
