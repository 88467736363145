import React, { Component } from "react";
import "./events.css";

import EventCard from "../components/eventCard";
import legacyBricks from "../images/legacyBricks.jpg";
import bookSale1 from "../images/bookSale1.jpg";
import bookSale2 from "../images/agMusuem.JPG";
import jeans from "../images/jeans.jpg"
import legacyBrickForm from "../files/legacy_brick_form.docx";
import diningForDollars from "../files/diningForDollars.jpg"
import downloadIcon from "../icons/downloadIcon.png"
import { BrowserRouter as Router, Link } from "react-router-dom";
 const legacyBrickInfo="For $100 you can buy a two line, custom engraved Legacy Brick. Legcay Bricks will be used in the landscaping around the building. Click below to download the form and follow the instructions to purchase."
const bookSaleInfo= "There's another Book Sale coming soon!  3,000+ books to peruse. Old and New!  Something for everyone, every age, action packed to cook book.";
const eventCard1 = (
  <EventCard
    eventImage={legacyBricks}
    title={"Legacy Brick Fundraiser"}
    information={legacyBrickInfo}
    startDate={"Now"}
    endDate={"While Supplies Last"}
    width={"25%"}
    style={{ minWidth: "300px" }}
    height={"auto"}
    endTime={"7"}
    startTime={"24"}
    content={
      <div>
        <Router>
          <div     >
            <Link to={legacyBrickForm} target="_blank" className="downloadLink"  >
            <img className="downloadIcon" src={downloadIcon} alt=""></img> Legacy_Brick_Form.pdf
            </Link>
          </div>
        </Router>
      </div>
    }

  ></EventCard>
);

const eventCard2 = (
  <EventCard
    eventImage={bookSale1}
    title={"Book Sale"}
    information={
      bookSaleInfo
    }
    date={"October 2023"}
    width={"25%"}
    height={"auto"}
    startDate={"October 14 2023"}
    endDate={"October 2023"}
    startTime={"10:00am"}
    endTime={"03:00pm"}
    location={"Heritage Bank Parking Lot 2205 S 1st Street, Union Gap, WA"}
  ></EventCard>
);
const eventCard3 = (
  <EventCard
    eventImage={bookSale2}
    title={"Book Sale"}
    information={
      bookSaleInfo
    }
    date={"June 2023"}
    width={"25%"}
    height={"auto"}
    startDate={"June 2023"}
    endDate={"June 2023"}
    startTime={"TBD"}
    endTime={"TBD"}
    location={"TBD"}
  ></EventCard>
);
const eventCard4 = (
  <EventCard
    eventImage={jeans}
    title={"Dining for Dollars"}
    information={
      'Each meal bought, in-house or to-go, donates $1 per meal to benefit the future Union Gap Library and Community Center.'
    }
    
    width={"25%"}
    height={"auto"}
    startDate={"May 31 2023"}
    endDate={"May 31 2023"}
    startTime={"8:30"}
    endTime={"6:30"}
    location={"3211 Main St, Union Gap, WA 98903"}
    content= {<div>
    <Router>
      <div     >
        <Link to={diningForDollars} target="_blank" className="downloadLink"  >
        <img className="downloadIcon" src={downloadIcon} alt=""></img> Dining for Dollars Info
        </Link>
      </div>
    </Router>
  </div>
    }
  ></EventCard>
);

const eventCard1Mobile = (
  <EventCard
    eventImage={legacyBricks}
    title={"Legacy Brick Fundraiser"}
    information={legacyBrickInfo}
    startDate={"Now"}
    endDate={"While Supplies Last"}
    width={"85%"}
    height={"auto"}
    endTime={"7"}
    startTime={"24"}
    content={
      <div>
        <Router>
          <div>
            <Link
              to={legacyBrickForm}
              target="_blank"
              className="downloadLink"
            >
                <img className="downloadIcon" src={downloadIcon} alt="download"></img> Legacy_Brick_Form.pdf
            </Link>
          </div>
        </Router>
      </div>
    }
    
  ></EventCard>
);

class events extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    return (
      <div className="eventsParent">
        <div className="eventsContainer">
          <h1 className="eventsHeading">Events / Fundraisers</h1>
          {this.props.mobile && (
            <div className="eventCardContainerMobile">{eventCard1Mobile} </div>
          )}
          {!this.props.mobile && (
            <div className="eventCardContainer">
              {eventCard1} {eventCard2}   {eventCard3} {eventCard4}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default events;
