import React from "react";

import "./progressBar.css";

const COUNTER = 100;
const TIMEOUT = 5;
const ENDDATE = new Date("10/1/2023");
const MONEYRAISED = 106100;
class progressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      current: 0,
      goal: 500000,
      daysLeft: 0,
      animationRan:false,
    };
    this.increaseProgress = this.increaseProgress.bind(this);
    this.calculatePerentage = this.calculatePerentage.bind(this);
    this.calculateDaysLeft = this.calculateDaysLeft.bind(this);
  }
  componentDidMount(props) {
    this.calculateDaysLeft();
  }
  componentDidUpdate(prevProps){
    if(this.prevProps!== this.props){
      if(this.props.Index===2 && !this.state.animationRan && !this.props.Mobile){
        this.setState({animationRan:true});
        let intervalId = setInterval(this.increaseProgress,TIMEOUT);
        this.setState({intervalId: intervalId});
      }
      if(this.props.Index!==2 && this.state.animationRan && !this.props.Mobile){
        this.setState({animationRan:false, current:0,});
        clearInterval(this.state.intervalId);
      }
      if(this.props.Index===4 && !this.state.animationRan && this.props.Mobile){
        this.setState({animationRan:true});
        let intervalId=setInterval(this.increaseProgress,TIMEOUT);
        this.setState({intervalId: intervalId});
      }
      if(this.props.Index!==4 && this.state.animationRan && this.props.Mobile){
        this.setState({animationRan:false, current:0,});
        clearInterval(this.state.intervalId);
      }
    }
   
  }

  calculateDaysLeft() {
    const currentDate = new Date();
    const diffInMs = Math.abs(ENDDATE - currentDate);
    this.setState({
      daysLeft: Math.ceil(diffInMs / (1000 * 60 * 60 * 24)),
    });
  }
  calculatePerentage() {
    this.setState({
      percentage: Math.floor((this.state.current / this.state.goal) * 100),
    });
  }
 increaseProgress(count) {
   if(this.state.current<MONEYRAISED){
      let inc = this.state.current;
      inc += COUNTER;
      this.setState({ current: inc });
      this.calculatePerentage();
   }
   else{
    clearInterval(this.state.intervalId);
   }
    
   
  }
  render() {
    return (
      <div className="progressBarContainer">
        <div id="amountRaised">${this.state.current.toLocaleString()}</div>
        
        <div className="thermometer">
          <div
            className="progress"
            style={{
              width: this.state.percentage + "%",
            }}
          ></div>
        </div>
        <div className="statsParent">
          <div className="stats">
            <b className="statText">Progress</b>
            <div id="percentage"> {this.state.percentage}%</div>
          </div>
          <div className="stats">
            <b className="statText">Days Left</b>
            <div id="daysLeft">{this.state.daysLeft} </div>
          </div>
          <div className="stats">
            <b className="statText"> Goal</b>
            <div id="goal">${this.state.goal.toLocaleString()}</div>
          </div>
        </div>

      
      </div>
    );
  }
}
export default progressBar;
