import React from "react";
import "./buildingPlans2.css";

class buildingPlans2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="buildingPlans2Parent"
        style={{
          height: this.props.height - 50 + "px",
          position: "relative",
          bottom: "0px",
        }}
      >
        <div
          className="floorPlanParent2"
                >
          <h1 className="buildingHeader">Floor Plan</h1>
          <p className="buildingPMobile">
            The new Union Gap Library/Community Center will be accessed via an
            entry courtyard designed to welcome visitors as well as to host
            gatherings and casual public use. The lobby serves both the Library
            and Community Center, providing restrooms and support facilities for
            both sides. The Library (west wing) is just over 2,000 square feet
            and will feature a generous Children’s Area with a reading corner,
            fun furnishings and mobile shelving to allow the flexibility to
            support a wide range of programs. The Teen Area will house a
            connection bar for use of electronic devices as well as booth
            seating for study and team projects. A nook on the south side is set
            aside as a Reading Area which may include a fireplace if budget
            allows. A focal Check-Out (Circulation) Desk provides ease of access
            for patrons as well as full visibility for staff. The Community
            Center (east wing) has been designed at roughly 2,000 square feet
            and features a nice sized gathering space facing south for
            presentations and performances. A double-sided fireplace is shown
            facing both the gathering space and an exterior patio to the east
            which is again dependent on available funding. A kitchen has been
            included to support Meals on Wheels and other community functions.
            The floor plan is rounded out with an office and support spaces.
          </p>
        </div>
      </div>
    );
  }
}
export default buildingPlans2;
