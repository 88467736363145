import React, { Component } from 'react';
import EventCard from "../components/eventCard"
import bookSale2 from "../images/agMusuem.JPG";
const bookSaleInfo= "There's another Book Sale coming soon!  3,000+ books to peruse. Old and New!  Something for everyone, every age, action packed to cook book.";

const eventCard3Mobile = (
    <EventCard
      eventImage={bookSale2}
      title={"Book Sale"}
      information={
        bookSaleInfo
      }
      date={""}
      width={"85%"}
        height={"auto"}
        startDate={"June 2023"}
        endDate={"June 2023"}
        startTime={"TBD"}
        endTime={"TBD"}
        location={"TBD"}
    ></EventCard>
  );
class eventsMobile3 extends Component {
    
    render() {
        return (
            <div>
            <div className="eventsParent" >
            <div className="eventsContainer">
        <h1 className="eventsHeading">Events / Fundraisers</h1>
       <div className="eventCardContainerMobile">{eventCard3Mobile} </div>
   </div>
 </div>
       </div>
        );
    }
}

export default eventsMobile3;