import React, { Component } from "react";
import Profile from "../components/profile";
import Debra from "../profiles/debra.jpg"
import Imelda from "../profiles/Imelda.jpg"
import Jim from "../profiles/jim.jpg";
import "./membersMobile.css";
import Grace from "../profiles/grace.jpg";

class member3 extends Component {
  render() {
    return (
      <div
        className="parentContainerMembersMobile"
        style={{
          height: this.props.height - 50 + "px",
          postion: "relative",
          bottom: "0px",
        }}
      >
        <h1 className="pageTitleMobile">Board Members</h1>

        <Profile
          Name={"Debra Delatorre"}
          Description={"Board Member"}
          Image={Debra}
          width={"50%"}
          height={"25%"}
        />

        <Profile
          Name={"Imelda Vargas"}
          Description={"Board Member"}
          Image={Imelda}
          width={"50%"}
          height={"25%"}
        />
        <Profile
          Name={"Jim Honeyford"}
          Description={"Honorary Board Member"}
          Image={Jim}
          width={"50%"}
          height={"25%"}
        ></Profile>
        <Profile
          Name={"Grace Federspiel"}
          Description={"Student Member"}
          Image={Grace}
          width={"50%"}
          height={"25%"}
        ></Profile>
      </div>
    );
  }
}

export default member3;
