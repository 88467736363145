import React from "react";
import infoIcon from "../icons/infoIcon.png";
import exitIcon from "../icons/dismissIcon.png";
import "./alert.css";

class profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  toggleVisible(){
      this.setState({
          visible:false,
      })
  }
  render() {
    return (
      <div>
        {this.state.visible && (
          <div className="alertParent">
            <img src={infoIcon} alt="success" className="infoIcon" />
            <div>
              <h3 className="alertHeading">Announcement</h3>
              <p className="alertText">
              Under Construction: Our library is set for completion in Spring 2024. Stay tuned!"
              </p>
            </div>
            <img src={exitIcon} alt="dismissAlert" className="alertDismiss" onClick={()=> this.toggleVisible()}/>
          </div>
        )}
      </div>
    );
  }
}
export default profile;
