import React, { Component } from 'react';
import "./donatePage.css"

class donorLevels extends Component {
    constructor(props) {
        super(props);

    }



    

    render() {
        return (
            <div>
                      <div className="donationAmountContainerMobile">
                          <h1 className="donorLevelPage">Donor Levels</h1>
              <div className="donorLevelContainer" id="platinum">
              <h1 className="donorLevelHeading">Platinum Donor<h1 className="levelAmount"><br></br>$5,000+ </h1></h1>
              <ul className="donorLevelUl">
                <li>Plaque</li>
                <li>Name in newsletter with photo</li>
                <li>Honorable Mention on our FB page and Website</li>
                <li>
                  Name on plaque in entry at the Library and Community Center
                </li>
                <li>Personalized Legacy Brick</li>
                <li>Invite to our Annual Donor Event</li>
              </ul>
              </div>
              <div className="donorLevelContainer" id="gold">
              <h1 className="donorLevelHeading">Gold Donor<h1 className="levelAmount"><br></br>$1,000+</h1></h1>
              <ul className="donorLevelUl">
                <li>Name on group plaque in entry</li>
                <li>Name in newsletter with photo</li>
                <li>Honorable Mention on our FB page and Website</li>
                <li>5 x 7 plaque</li>
                <li>Invite to our Annual Donor Event</li>
              </ul>
              </div>
              <div className="donorLevelContainer" id="silver">
              <h1 className="donorLevelHeading">Silver Donor<h1 className="levelAmount"><br></br>$500+</h1></h1>
              <ul className="donorLevelUl">
                <li>Name in newsletter with group photo</li>
                <li>Honorable Mention on our FB page and Website</li>
                <li>4 x 6 plaque</li>
                <li>Invite to our Annual Donor Event</li>
              </ul>
              </div>
              <div className="donorLevelContainer" id="bronze">
              <h1 className="donorLevelHeading">Bronze Donor <h1 className="levelAmount"><br></br>$100+</h1></h1>
              <ul className="donorLevelUl">
                <li>Name in newsletter</li>
                <li> Listing on FB page and Website</li>
              </ul>
              </div>
              
              <div className="donorLevelContainer">
              <h1 className="donorLevelHeading">Patron Donor <h1 className="levelAmount"><br></br>&lt; $99</h1></h1>
              <ul className="donorLevelUl">
                <li>Name in newsletter</li>
                <li> Listing on FB page</li>
              </ul>
              </div>
              <div className="donorLevelContainer">
              <h1 className="donorLevelHeading">Challenge Level<h1 className="levelAmount"><br></br> Group </h1></h1>
              <ul className="donorLevelUl">
                <li>Donate and Challenge your Freinds!</li>
                <li>Name in newsletter with group photo</li>
                <li>Listed on UGLCC Challenge Plaque</li>
                <li> Special seating at our Annual Donor Event</li>
                <li> Honorable Mention on our FB page and Website</li>
              </ul>
              </div>
            </div>
            </div>
        );
    }
}


export default donorLevels;