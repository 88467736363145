import React from "react";
import "./membersMobile.css";
import Profile from "../components/profile";

import Kathy from "../profiles/kathy.jpg"
import Helen from "../profiles/helen.jpg";
import Theresa from "../profiles/theresa.JPG";
import Benine from "../profiles/bernie.jpeg"
class members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div className="parentContainerMembersMobile" style={{height: (this.props.height-50)+'px' , postion:"relative", bottom:'0px'}}>
             <h1 className="pageTitleMobile">Board Members</h1>
             <Profile
          Name={"Theresa Charvet"}
          Image={Theresa}
          Description={"Secretary"}
          width={"50%"}
          height={"25%"}
        />
            <Profile
          Name={"Helen Canatsey"}
          Description={"Board Member"}
          Image={Helen}
          width={"50%"}
          height={"25%"}
        />
       
        
             <Profile
        Name={"Benine McDonnell"}
          Description={"Board Member"}
          Image={Benine}
          width={"50%"}
          height={"25%"}>
        </Profile>
        <Profile
        Name={"Kathy Cluck"}
          Description={"Honorary Board Member"}
          Image={Kathy}
          width={"50%"}
          height={"25%"}>
        </Profile>
     
      </div>
    );
  }
}
export default members;
