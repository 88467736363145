import React from "react";
import "./membersMobile.css";
import Profile from "../components/profile";
import Julie from "../profiles/julie.jpg"
import Mark from "../profiles/mark.jpg";
import Lilia from "../profiles/Lilia.jpg"
import eddy from "../profiles/eddy.jpg"

class members1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="parentContainerMembersMobile" style={{height: (this.state.props-50)+'px' , postion:"relative", bottom:'0px'}}>
             <h1 className="pageTitleMobile">Board Members</h1>
             <Profile
          Name={"Mark Crouchet"}
          Description={"Board Chairman"}
          Image={Mark}
          width={"50%"}
          height={"30%"}
        />
       <Profile
          Name={"Julie Schilling"}
          Description={"Vice-Chair"}
          Image={Julie}
          width={"50%"}
          height={"30%"}
        />
       <Profile
          Name={"Lilia Gomez-Munguia"}
          Description={"Treasurer"}
          Image={Lilia}
          width={"50%"}
          height={"30%"}
        />
            <Profile
          Name={"Eddy Hernandez"}
          Description={"Board Member"}
          Image={eddy}
          width={"50%"}
          height={"25%"}
        />

      </div>
    );
  }
}
export default members1;
