import React, { Component } from "react";
import FlipPage from "react-flip-page";
import Header from "./components/header";
import Alert from "./components/alert"
import upArrow from "./icons/point_arrow_up.png";
import downArrow from "./icons/point_arrow_down.png";
import DonatePage from "./pages/donatePage";
import "./App.css";
import BuildingPlans from "./pages/buildingPlans";
import BuildingPlans2 from "./pages/buildingPlans2";
import Members1 from "./pages/members1";
import Members2 from "./pages/members2";
import Members3 from "./pages/member3";
import Members from "./pages/members";
import Events from "./pages/events";
import EventsMobile2 from "./pages/eventsMobile2";
import EventsMobile3 from "./pages/eventsMobile3";
import EventsMobile4 from "./pages/eventsMobile4";
import Sponsors from "./pages/sponsors";
import DonorLevels from "./pages/donorLevels";
import ThankYou from "./pages/thankYou";
import nameFile from "./files/donationNames.csv";
import Gallery from "./pages/gallery";
import { loadStripe } from "@stripe/stripe-js";
const pk_test = process.env.REACT_APP_PK_KEY || " ";
export const stripePromise = loadStripe(pk_test);
const ANIMATION_DURATION = 200;
const SCROLLFLIP = 200;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      scrollIndex: 0,
      width: 0,
      height: 0,
      pageFlipping: false,
      greatestHeight: 0,
      namesLoaded: false,
      namesObj: [],
    };
    this.updatePageState = this.updatePageState.bind(this);
    this.flipToPage = this.flipToPage.bind(this);
    this.upPage = this.upPage.bind(this);
    this.downPage = this.downPage.bind(this);
    this.detectScroll = this.detectScroll.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.pageFlipStarted = this.pageFlipStarted.bind(this);
    this.loadNames = this.loadNames.bind(this);
  }

  componentDidMount() {
    if (!this.state.namesLoaded) {
      this.loadNames();
    }
    window.addEventListener("load", function () {
      window.scrollTo(0, 0);
    });
    document.addEventListener("touchmove", function (e) {
      e.preventDefault();
    });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  async loadNames() {
    await fetch(nameFile)
      .then((r) => r.text())
      .then((text) => {
        this.setState({
          namesLoaded: true,
          namesObj: this.csvJSON(text.toString()),
        });
      });
  }
  csvJSON(csv) {
    var lines = csv.split("\n");
    lines[0] = lines[0].toString().replace(/\s+/g, "");
    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length - 1; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    return result; //JSON
  }
  detectScroll(e) {
    e.preventDefault();
    let deltaY = e.deltaY;
    if (deltaY < -1) {
      this.setState({
        scrollIndex: this.state.scrollIndex + deltaY,
      });
    }
    if (deltaY > 1) {
      this.setState({
        scrollIndex: this.state.scrollIndex + deltaY,
      });
    }

    if (this.state.scrollIndex >= SCROLLFLIP) {
      this.upPage();
      this.setState({
        scrollIndex: 0,
      });
    }
    if (this.state.scrollIndex <= -SCROLLFLIP) {
      this.downPage();
      this.setState({
        scrollIndex: 0,
      });
    }
  }

  pageFlipStarted(e) {
    this.setState({
      pageFlipping: true,
    });
  }

  updatePageState(e) {
    this.setState({
      pageIndex: e,
      pageFlipping: false,
    });
  }
  upPage() {
    this.flipPage.gotoNextPage();
  }
  downPage() {
    this.flipPage.gotoPreviousPage();
  }
  async flipToPage(index) {
    this.flipPage.gotoPage(index);
  }
  render() {
    return (
      <div className="App" onWheel={(e) => this.detectScroll(e)}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Tangerine"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        ></meta>

        <body style={{ height: this.state.height }}>
          {this.state.width > 800 && (
            <Header
              FlipToPage={this.flipToPage}
              ScreenWidth={this.state.width}
              index={this.state.pageIndex}
              Mobile={false}
            />
          )}
          {this.state.width <= 800 && (
            <Header
              FlipToPage={this.flipToPage}
              ScreenWidth={this.state.width}
              index={this.state.pageIndex}
              Mobile={true}
            />
          )}

          {this.state.width > 800 && (
            
            <div
              className="flipPage"
              style={{ height: this.state.height - 50 + "px" }}
            >
                    <Alert/>
              
              <FlipPage
                responsive={true}
                uncutPages={false}
                animationDuration={ANIMATION_DURATION}
                showTouchHint={false}
                showHint={true}
                showSwipeHint={false}
                loopForever={true}
                style={{ zIndex: 0 }}
                onPageChange={(e) => {
                  this.updatePageState(e);
                }}
                ref={(component) => {
                  this.flipPage = component;
                }}
              >
             
                <div
                  className="topPage"
                  style={{ height: this.state.height - 50 + "px" }}
                >
               
                  <div
                    alt="image3d"
                    className="drawing3d"
                    style={{ height: this.state.height - 50 + "px" }}
                  >
                    <div className="homeTextParent">
                      <h1 className="homeHeading">Our Mission</h1>
                      <p className="missionStatement">
                        The Friends of Union Gap Library and Community Center is
                        a public nonprofit organization formed in 2019 whose
                        fund raising goals are two-fold: 1) To raise funds
                        needed to complete the construction and outfitting of
                        the new library, community center and commercial
                        kitchen, and 2) To provide an ongoing source of funds
                        for programs, services, classes and technical resources
                        for the citizens of Union Gap and surrounding
                        communities.
                      </p>

                      <button
                        className="donateButton"
                        onClick={() => {
                          this.flipToPage(2);
                        }}
                      >
                        Donate
                      </button>
                    </div>
                  </div>
                </div>

                <BuildingPlans
                  style={{ height: this.state.height - 50 + "px" }}
                  Mobile={false}
                  Index={this.state.pageIndex}
                />

                <DonatePage Mobile={false} Index={this.state.pageIndex} />
                <ThankYou
                  height={this.state.height}
                  width={this.state.width}
                  NamesLoaded={this.state.namesLoaded}
                  NamesObj={this.state.namesObj}
                  Mobile={false}
                  index={this.state.pageIndex}
                />
                <Sponsors
                  height={this.state.height}
                  width={this.state.width}
                  index={this.state.pageIndex}
                  mobile={false}
                ></Sponsors>
                <Events />

                <Members
                  style={{ height: this.state.height - 50 + "px" }}
                  Mobile={false}
                />
                <Gallery
                  height={this.state.height}
                  width={this.state.width}
                  index={this.state.pageIndex}
                  mobile={false}
                />
              </FlipPage>
              <p className="designText">
                design by:
                <a href="https://aaronahlberg.dev"> aaronahlberg.dev</a>
              </p>
            </div>
          )}
          {this.state.width <= 800 && (
            <div
              className="flipPage"
              style={{ height: this.state.height - 50 + "px" }}
            >
              <Alert/>
              <FlipPage
                responsive={true}
                uncutPages={false}
                animationDuration={ANIMATION_DURATION}
                showTouchHint={true}
                showHint={true}
                showSwipeHint={false}
                loopForever={true}
                threshold={5}
                style={{ zIndex: 0 }}
                onPageChange={(e) => {
                  this.updatePageState(e);
                }}
                ref={(component) => {
                  this.flipPage = component;
                }}
              >
                <div
                  className="topPage"
                  style={{ height: this.state.height - 50 + "px" }}
                >
                  <div
                    alt="image3d"
                    className="drawing3dMobile"
                    style={{ height: this.state.height - 50 + "px" }}
                  >
                    <div className="homeTextParentMobile">
                      <h1 className="homeHeading">Our Mission</h1>
                      <p className="missionStatement">
                        The Friends of Union Gap Library and Community Center is
                        a public nonprofit organization formed in 2019 whose
                        fund raising goals are two-fold: 1) To raise funds
                        needed to complete the construction and outfitting of
                        the new library, community center and commercial
                        kitchen, and 2) To provide an ongoing source of funds
                        for programs, services, classes and technical resources
                        for the citizens of Union Gap and surrounding
                        communities.
                      </p>{" "}
                      <button
                        className="donateButton"
                        onClick={() => {
                          this.flipToPage(4);
                        }}
                      >
                        Donate
                      </button>
                    </div>
                  </div>
                </div>
                <BuildingPlans
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                  Index={this.state.pageIndex}
                  style={{ height: this.state.height - 50 + "px" }}
                />
                <BuildingPlans2
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                />

                <DonorLevels />
                <DonatePage
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                  Index={this.state.pageIndex}
                />
                <ThankYou
                  NamesLoaded={this.state.namesLoaded}
                  NamesObj={this.state.namesObj}
                  Mobile={true}
                  index={this.state.pageIndex}
                  height={this.state.height}
                  width={this.state.width}
                />
                <Sponsors
                  height={this.state.height}
                  width={this.state.width}
                  index={this.state.pageIndex}
                  Mobile={true}
                ></Sponsors>
                <Events
                  mobile={true}
                  style={{
                    height: this.state.height - 50 + "px",
                    bottom: "0px",
                  }}
                />
                    <EventsMobile4
                  style={{
                    height: this.state.height - 50 + "px",
                    bottom: "0px",
                  }}
                >
                  {" "}
                </EventsMobile4>
                <EventsMobile2
                  style={{
                    height: this.state.height - 50 + "px",
                    bottom: "0px",
                  }}
                >
                  {" "}
                </EventsMobile2>
                <EventsMobile3
                  style={{
                    height: this.state.height - 50 + "px",
                    bottom: "0px",
                  }}
                >
                  {" "}
                </EventsMobile3>
            
                <Members1
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                />
                <Members2
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                />
                <Members3
                  width={this.state.width}
                  height={this.state.height}
                  Mobile={true}
                />
                <Gallery
                  height={this.state.height}
                  width={this.state.width}
                  index={this.state.pageIndex}
                  Mobile={true}
                />
              </FlipPage>
            </div>
          )}

          <div className="arrowContainer">
            <input
              alt="upArrow"
              type="image"
              src={upArrow}
              className="arrow"
              id="upArrow"
              onClick={() => this.downPage()}
            />
            <text className="pageNumber"> {this.state.pageIndex + 1}</text>
            <input
              alt="upArrow"
              type="image"
              src={downArrow}
              className="arrow"
              id="downArrow"
              onClick={() => this.upPage()}
            />
          </div>
        </body>
      </div>
    );
  }
}

export default App;
