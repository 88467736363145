import React, { Component } from "react";

import "./thankYou.css";

const DonationName = ({Name, Amount}) => (
    <div className="donationNameParent"> 
       {Amount<100 && <p className="nameText" id="patronSize">{Name}</p>} 
     {Amount>=100 && Amount<500 && <p className="nameText" id="bronzeSize">{Name}</p>} 
     {Amount>=500 && Amount<1000 &&<p className="nameText" id="silverSize">{Name}</p>}
     {Amount>=1000 && Amount<5000 &&<p className="nameText" id="goldSize">{Name}</p>}
     {Amount>=5000 &&<p className="nameText" id="platinumSize">{Name}</p>}
    </div>

    
)

class thankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.updateImageSize= this.updateImageSize.bind(this);
  }

  componentDidMount() {

  }

  updateImageSize(width, height){
    var images= document.querySelectorAll('.nameText');
    let newWidth= 1 * Math.sqrt( (width*height) / (images.length/2));
            images.forEach(function (a) {
             a.style.width= newWidth+"px";
              a.style.fontSize= newWidth/7+'px';
       })
         
 }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props.width && ((this.props.index===3&&!this.props.Mobile)|| (this.props.index===5&&this.props.Mobile))){
            this.updateImageSize(this.props.width, this.props.height);
      
    }
  }
 

  render() {
    return (
      <div className="thankYouParent">
       <div className="namesContainer">
          {this.props.NamesLoaded &&
            this.props.NamesObj.map((x) => (
             <DonationName Name={x.Name} Amount={x.Amount }/>
            ))}
        </div>
     
        <div className="thankYouTextParent">  <h1 className="thankYouHeading">
          A Special Thank you to these Special people!
        </h1>
        <p className="thankYouP">
          None of this would be possible without the continued help and support
          of the Community!
        </p>
        </div>
      
      </div>
    );
  }
}

export default thankYou;
