import React from "react";

import design1 from "../images/dd.png";
import design2 from "../images/dd2.png";
import design3 from "../images/dd3.png";
import design4 from "../images/dd4.png";
import buildingPlansPdf from "../files/building_plans.pdf";
import constructionCosts from "../files/construction_costs.pdf";
import downloadIcon from "../icons/downloadIcon.png";
import btnRight from "../icons/slideshowButtonRight.png";
import btnLeft from "../icons/slideshowButtonLeft.png";
import Modal from "react-modal";
import exitIcon from "../icons/exitIcon.png";
import "./buildingPlans.css";
import { BrowserRouter as Router, Link } from "react-router-dom";
const SLIDESHOWDELAY = 5000;
const picArray = [design1, design2, design3, design4];
Modal.setAppElement("#root");
class buildingPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideshowImage: picArray[0],
      slideshowStarted: false,
      isOpen:false,
      picToShow:null,
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.backSlide = this.backSlide.bind(this);
    this.startSlideshow = this.startSlideshow.bind(this);
    this.restartSlideshow = this.restartSlideshow.bind(this);
    this.stopSlideshow = this.stopSlideshow.bind(this);
    this.gotoNextSlide= this.gotoNextSlide.bind(this);
    this.toggleModal= this.toggleModal.bind(this);
  }
  toggleModal(picSrc) {
    this.setState({isOpen:!this.state.isOpen, picToShow: picSrc});
   }
  componentDidMount() {}
 componentDidUpdate(prevProps) {
    if (this.prevProps !== this.props && !this.props.Mobile) {
      if (this.props.Index === 1 && !this.state.slideshowStarted) {
       this.startSlideshow();
      }
      if (this.props.Index !== 1 && this.state.slideshowStarted) {
        this.stopSlideshow();
      }
    }
    if (this.prevProps !== this.props && this.props.Mobile) {
      if (this.props.Index === 1 && !this.state.slideshowStarted) {
        this.startSlideshow();
      }
      if (this.props.Index !== 1 && this.state.slideshowStarted) {
        this.stopSlideshow();
      }
    }
  }
  componentWillUnmount() {
   this.stopSlideshow();
  }
 stopSlideshow() {
    clearInterval(this.state.intervalId);
    this.setState({ slideshowStarted: false });
  }
 startSlideshow() {
  if(!this.startSlideshowStarted){
    let intervalId =  setInterval( this.nextSlide, SLIDESHOWDELAY);
    this.setState({ slideshowStarted: true, intervalId: intervalId });
  }
  }
  restartSlideshow() {
    this.stopSlideshow();
    this.startSlideshow();
  }
  nextSlide() {
    let currentIndex = picArray.indexOf(this.state.slideshowImage);
    var x = document.querySelectorAll(".slideshowImage");
    this.setState({
      slideshowImage: picArray[(currentIndex + 1) % 4],
    });
    x.forEach((a) => {(a.src = picArray[(currentIndex + 1) % 4]);});
  }
 

  backSlide() {
    let currentIndex = picArray.indexOf(this.state.slideshowImage);
    var x = document.querySelectorAll("#slideshowImage");
    if (currentIndex === 0) {
      this.setState({
        slideshowImage: picArray[picArray.length - 1],
      });
      x.forEach((a) => (a.src = picArray[picArray.length - 1]));
    } else {
      this.setState({
        slideshowImage: picArray[(currentIndex - 1) % 4],
      });
      x.forEach((a) => (a.src = picArray[(currentIndex - 1) % 4]));
    }
    this.restartSlideshow();
  }
  gotoNextSlide() {
    
    let currentIndex = picArray.indexOf(this.state.slideshowImage);
    var x = document.querySelectorAll(".slideshowImage");
    this.setState({
      slideshowImage: picArray[(currentIndex + 1) % 4],
    });
    x.forEach((a) => (a.src = picArray[(currentIndex + 1) % 4]));
    this.restartSlideshow();
  }
  render() {
    return (
      <div>
                 <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={200}
      >
        <img className="fullGalleryImage" src={this.state.picToShow} alt=""></img>
        <img src={exitIcon} alt='src' className="exitIcon" onClick={this.toggleModal}/>
      </Modal>
        {!this.props.Mobile && (
          <div
            className="parentContainerBuilding"
            style={{
              height: this.props.height - 50 + "px",
              position: "relative",
              bottom: "0px",
            }}
          >
            <div className="carouselParent">
              <img
                className="slideshowImage"
                id="slideshowImage"
                src={this.state.slideshowImage}
                alt=""
                onClick={() => this.toggleModal(this.state.slideshowImage)}
              ></img>
              <div className=" arrowContainerSlidehow">
                <img
                  alt=""
                  src={btnLeft}
                  className="slideshowButton"
                  onClick={() => this.backSlide()}
                />
                  <p className="pageCounter">
                {picArray.indexOf(this.state.slideshowImage) + 1} / 4
              </p>
                <img
                  alt=""
                  src={btnRight}
                  className="slideshowButton"
                  onClick={() => this.gotoNextSlide()}
                />
              </div>
            
            </div>
            <div className="designInfoParent">
              <div className=" floorPlanParent">
                <h1 className="buildingHeader"> Floor Plan</h1>
                <p className="buildingP">
                  The new Union Gap Library/Community Center will be accessed
                  via an entry courtyard designed to welcome visitors as well as
                  to host gatherings and casual public use. The lobby serves
                  both the Library and Community Center, providing restrooms and
                  support facilities for both sides. The Library (west wing) is
                  just over 2,000 square feet and will feature a generous
                  Children’s Area with a reading corner, fun furnishings and
                  mobile shelving to allow the flexibility to support a wide
                  range of programs. The Teen Area will house a connection bar
                  for use of electronic devices as well as booth seating for
                  study and team projects. A nook on the south side is set aside
                  as a Reading Area which may include a fireplace if budget
                  allows. A focal Check-Out (Circulation) Desk provides ease of
                  access for patrons as well as full visibility for staff. The
                  Community Center (east wing) has been designed at roughly
                  2,000 square feet and features a nice sized gathering space
                  facing south for presentations and performances. A
                  double-sided fireplace is shown facing both the gathering
                  space and an exterior patio to the east which is again
                  dependent on available funding. A kitchen has been included to
                  support Meals on Wheels and other community functions. The
                  floor plan is rounded out with an office and support spaces.
                </p>
              </div>
              <div className="exteriorParent">
                <h1 className="buildingHeader">Exterior Elevations</h1>
                <p className="buildingP">
                  The exterior of the building is designed to blend with City
                  Hall in both the use of shed roofs and similar cladding
                  materials. Windows will predominately face City Hall and the
                  courtyard and include built-in sunshades to aid in sun
                  control. Doors will be provided to allow ample access to
                  exterior areas intended to host community activities.
                </p>
                <div className="downloadLinkContainer">
                  <Router>
                    <div>
                      <Link
                        className="downloadLink"
                        to={buildingPlansPdf}
                        target="_blank"
                      >
                        {" "}
                        <img
                          className="downloadIcon"
                          src={downloadIcon}
                        ></img>{" "}
                        Floor_Plans.pdf
                      </Link>
                    </div>
                    <div>
                      <Link
                        className="downloadLink"
                        to={constructionCosts}
                        target="_blank"
                      >
                        <img className="downloadIcon" src={downloadIcon}></img>{" "}
                        Construction_Costs.pdf
                      </Link>
                    </div>
                  </Router>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.Mobile && (
          <div
            className="parentContainerBuildingMobile"
            style={{
              height: this.props.height - 50 + "px",
              position: "relative",
              bottom: "0px",
            }}
          >
            <div className="carouselParentMobile">
              <img
                className="slideshowImageMobile"
                id="slideshowImage"
                src={this.state.slideshowImage}
                alt=""
                onClick={() => this.toggleModal(this.state.slideshowImage)}
              />
              <div className=" arrowContainerSlidehowMobile">
                {" "}
                <img
                  alt=""
                  src={btnLeft}
                  className="slideshowButton"
                  onClick={() => this.backSlide()}
                />
                <img
                  alt=""
                  src={btnRight}
                  className="slideshowButton"
                  onClick={() => this.gotoNextSlide()}
                />
              </div>
              <p className="pageCounterMobile">
                {picArray.indexOf(this.state.slideshowImage) + 1} / 4{" "}
              </p>
            </div>
            <div
              className="floorPlanParent3"
              style={{ fontSize: this.props.height / 44 }}
            >
              <div className="exteriorParent2">
                <h1 className="buildingHeader">Exterior Elevations</h1>
              </div>
              <p className="buildingPMobile">
                The exterior of the building is designed to blend with City Hall
                in both the use of shed roofs and similar cladding materials.
                Windows will predominately face City Hall and the courtyard and
                include built-in sunshades to aid in sun control. Doors will be
                provided to allow ample access to exterior areas intended to
                host community activities.
              </p>

              <div className="downloadLinkContainer3">
                <Router>
                  <div>
                    <Link
                      className="downloadLink"
                      to={buildingPlansPdf}
                      target="_blank"
                    >
                      <img className="downloadIcon" src={downloadIcon}></img>{" "}
                      Floor_Plans.pdf
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="downloadLink"
                      to={constructionCosts}
                      target="_blank"
                    >
                      <img className="downloadIcon" src={downloadIcon}></img>{" "}
                      Construction_Costs.pdf
                    </Link>
                  </div>
                </Router>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default buildingPlans;
