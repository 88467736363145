import React, { Component } from 'react';
import "./events.css"
import EventCard from "../components/eventCard"
import bookSale1 from "../images/bookSale1.jpg";
const bookSaleInfo= "There's another Book Sale coming soon!  3,000+ books to peruse. Old and New!  Something for everyone, every age, action packed to cook book. Date and time yet to be announced.";

class eventsMobile2 extends Component {

    render() {
        const eventCard2Mobile = (
          <EventCard
          eventImage={bookSale1}
          title={"Book Sale"}
          information={
            bookSaleInfo
          }
          width={"85%"}
          height={"auto"}
          startDate={"October 14 2023"}
          endDate={"October 14 2023"}
          startTime={"10:00am"}
          endTime={"03:00pm"}
          location={"Heritage Bank Parking Lot 2205 S 1st Street, Union Gap, WA"}
        ></EventCard>
          );
        return (
            <div>
                 <div className="eventsParent" >
        <div className="eventsContainer">
        <h1 className="eventsHeading">Events / Fundraisers</h1>
            <div className="eventCardContainerMobile">{eventCard2Mobile} </div>
        </div>
      </div>
            </div>
        );
    }
}

export default eventsMobile2;