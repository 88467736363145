import React from "react"
import "./profile.css"

class profile extends React.Component{
    constructor(props){
        super(props);
        this.state={
        }
    }
    render(){
        return(
            <div className="profileParent" style={{width: this.props.width, height: this.props.height}}>
                <div className="profilePicDiv">
                <img className="profilePicture"src={this.props.Image} alt="profile pic"></img>
                <h2 className="name">{this.props.Name}</h2>
                <p className="title"> {this.props.Description}</p>
                </div>
                
               
            </div>
        );
    }
}
export default profile;